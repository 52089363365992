.dateAndTime.studentSide .boxiDetail{
    color: var(--success-4);
    background: var(--white);
    border: 1px solid var(--grey-3);
}

.parent{
    cursor: pointer;
}

.backbtn{
    padding: 0;
    max-width: max-content;
 }