.dateRangeWrapper{
    position: relative;
    height: 39vh;
    background: var(--white);
    border-radius: 5px;
    overflow: auto;
}

.dateRangeWrapper .selectInterviewContainer{
    max-width: 200px;
    max-height: 250px;
    overflow: auto;
}

.dateRangeWrapper .schedulerContainer{
    justify-content: flex-start;
}

.dateRangeWrapper .slotWrapper{
    width: 100%;
}

.slotWrapper label{
    color: var(--black-1) !important;
    border: 1px solid #E0EFFD !important;
    background: #E0EFFD;
    border-radius: 6px;
    padding: 8px 20px;
    padding-left: 20px !important;
}

.slotWrapper input:checked + label{
    color: var(--white) !important;
    border: 1px solid var(--black-1) !important;
}

.slotWrapper .slotsOuter{
    gap: 12px;
}

.slotsContainer{
    width: 100%;
    height: calc(100% - 70px);
    background: var(--white);
    padding: 40px;
    display: flex;
    overflow: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.slotsContainer .tabContent {
    border: 1px solid var(--grey-2);
    padding: 20px 30px;
    position: relative;
    border-radius: 5px;
}

.slotsContainer .weeklyTable{
    margin-top: 0;
}

.rowStraight{
    flex-direction: row;
    padding: 8px 12px;
}

.datePickWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 599px;
}

.datePickWrapper .heading{
    font-size: 18px;
    line-height: 20px;
    font-family: 'inter bold';
}

.dateRange input{
    padding: 10px;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
}

.dateRange{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: 14px;
    line-height: 120%;
}

.react-datepicker__input-container input{
    display: none !important;
}