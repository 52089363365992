.popupOuter {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    /* margin-top: 60px; */
    /* height: calc(100vh - 60px); */
    height: 100vh;
    position: fixed;
    padding: 20px;
    z-index: 10;
    background: rgba(23, 23, 23, 0.4);
    backdrop-filter: blur(5px);
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
    z-index: 999;
}

.popupContainer {
    position: relative;
    border-radius: 10px;
    width: 900px;
    margin: auto;
    height: 600px;
    background: var(--white);
    overflow: hidden;
}

.popupOuter.active {
    visibility: visible;
    opacity: 1;
}

.popupHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    background: #21222D;
    border-bottom: 1px solid var(--grey-3);
}

.popupTitle {
    font-family: 'Inter Medium';
    font-size: 16px;
    line-height: 22px;
    color: var(--white);
}
.popupBody{
    padding: 30px;
    width: 100%;
}

.calendarOuter{
    width: 100%;
    max-width: 440px;
    padding: 25px 20px;
    border: 1px solid var(--grey-3);
    border-radius: 10px;
}

.react-date-picker{
    width: 100%;
}

.calender-label{
    width: 100%;
    font-family: "ns-semibold";
    font-size: 14px;
    line-height: 20px;
}


.react-date-picker__wrapper{
    min-width: 260px;
    min-height: 32px;
    padding: 6px 8px;
    border-radius: 6px;
    border: 1px solid var(--grey-3);
    color: var(--black-4);
    cursor: pointer;
    /* background: var(--grey-1); */

    /* background: var(--white) url(https://mastersunion.s3.ap-south-1.amazonaws.com/assets/imgV2/datepickerpmcalender.svg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 97% 50%; */
}

.react-date-picker__inputGroup__input{
    color: var(--grey-4);
    cursor: pointer;
}

.react-date-picker__inputGroup__input:focus-visible{
    box-shadow: none;
    border: none;
    outline: none;
    background: transparent;
}

.react-date-picker__inputGroup__input:focus{
    background: transparent;
}
.react-date-picker__calendar{
    z-index: 2;
}

/* .react-date-picker__button:enabled{
    display: none;
} */


/* .react-date-picker__button{
    display: none;
} */

.react-date-picker__clear-button{
    display: none;
}

.react-date-picker__calendar-button{
    position: relative;
    padding: 0;
    min-width: 16px;
}

.react-date-picker__calendar-button::after{
    content: "";
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    background: url(https://mastersunion.s3.ap-south-1.amazonaws.com/assets/imgV2/datepickerpmcalender.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.react-date-picker__calendar{
    width: auto;
}

.react-calendar{
    border: none;
    border-radius: 6px;
    margin-top: 2px;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}

.react-calendar__navigation{
    /* margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--grey-2); */
    height: auto;
    display: flex;
    margin-bottom: 30px;
}

.react-calendar__navigation button {
    position: relative;
    background: transparent;
    outline: none;
    border: none;
}
.react-calendar__navigation__label{
    order: 1;
    text-align: left;
}

.react-calendar__navigation__prev2-button{
    display: none;
}

.react-calendar__navigation__next2-button{
    display: none;
}

.react-calendar__navigation__prev-button{
    order: 2;
    margin-right: 5px !important;
    color: transparent;
    min-width: 30px !important;
    min-height: 30px;
}

.react-calendar__navigation__prev-button::after{
    content: "";
    background: url(https://mastersunion.s3.ap-south-1.amazonaws.com/assets/imgV2/datepickerpmleft.svg);
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;    
}

.react-calendar__navigation__next-button{
    order: 3;
    color: transparent;
    min-width: 30px !important;
    min-height: 30px;
    background: var(--grey-4) !important;
    border-radius: 50%;
}

.react-calendar__navigation__next-button::after{
    content: "";
    background: url(https://mastersunion.s3.ap-south-1.amazonaws.com/assets/imgV2/datepickerpmright.svg);
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;    
}

.react-calendar__navigation__arrow{
    border-radius: 50%;
    cursor: pointer;
}

.react-calendar__navigation__label__labelText--from{
    font-size: 12px;
    font-family: 'Inter bold';
    line-height: 20px;
    color: var(--black-1);
    font-size: 18px;
}

.react-calendar__month-view__weekdays__weekday abbr{
    text-decoration: none;
}

.react-calendar__tile{
    border: none;
    background: transparent;
    padding: 5px;
}

.react-calendar__tile abbr{
    max-width: 30px;
    min-height: 30px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.react-calendar button{
    transition: 0.1s ease-in;
}

/* .react-calendar__tile--active{
    background-color: var(--primary-4) !important;
    color: var(--white) !important;
} */

/* .react-calendar__tile--active{

} */

.react-calendar__tile--active:enabled:hover, 
.react-calendar__tile--active:enabled:focus{
    background-color: var(--primary-4) !important;
    transition: 0.1s ease-in !important;
}

.react-calendar button:enabled:hover abbr{
    background: var(--black-1);
    color: var(--white);
    transition: 0.1s ease-in !important;
    cursor: pointer;
}

.isFilled .react-date-picker__wrapper{
    color: var(--black-4)
}

.isFilled .react-date-picker__inputGroup__input{
    color: var(--black-4)
}

.react-calendar__tile--now abbr{
    background: var(--black-1);
    color: var(--white);
    border: 1px solid var(--black-1) !important;
}

.rc-time-picker-input{
    width: 80px;
}

/* .react-calendar__tile {
    padding: 16px 12px;
    max-height: 50px;
} */

.schedulerContainer{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    gap: 40px;
}

/* .slots.active .slot{
    width: 50%;
    background: var(--black-1);
    border-color: var(--black-1);
    color: var(--white);
} */

/* .slots{
    width: calc(50% - 8px);
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    transition: 0.3s;
    text-align: center;
    padding: 12px 40px;
    font-size: 14px;
    color: var(--primary-4);
    border-radius: 6px;
    border: 2px solid var(--grey-3);
} */

.slotsOuter{
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}

.selectInterviewContainer{
    width: 100%;
    max-width: calc(100% - 480px);
}

.react-calendar__month-view__days{
    margin-top: 10px;
}

.selectInterviewContainer .month{
    font-family: 'Inter bold';
    line-height: 20px;
    color: var(--black-1);
    font-size: 18px;
}

.closeWhite{
    filter: invert(1);
    cursor: pointer;
}

/* //////////////////////////////////////////////// */

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
  border: none;
  border-radius: 7px;
  background-color: var(--dark);
  color: var(--white);
}

.react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
  border: none;
  border-radius: 7px;
  background-color: var(--dark);
  color: var(--white);
}

.react-datepicker-popper .react-datepicker__navigation {
  padding-top: 0;
  color: #000;
}

.react-datepicker {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.189);
  border: none !important;
  font-family: "Inter" !important;
}

.react-datepicker__header {
  border-bottom: solid 5px var(--light) !important;
  background: white !important;
}

.react-datepicker__current-month {
  color: var(--dark) !important;
}

.react-datepicker__day.react-datepicker__day--today {
  border-radius: 7px;
  border: solid 2px var(--brand) !important;
  background-color: white !important;
  color: var(--dark) !important;
  width: 25px;
  height: 25px;
  transition: 0.4s ease-in-out !important;
}

.react-datepicker__day.react-datepicker__day--selected {
  border: 1px solid var(--black-4) !important;
  transition: 0.4s ease-in-out !important;
  line-height: 190%;
  background: var(--black-4) !important;
  color: var(--white) !important;
}

/* .react-datepicker__day.react-datepicker__day--selected:hover{
    color: var(--white) !important;
} */

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  border: none;
  border-radius: 7px !important;
  background-color: var(--black-4) !important;
  color: var(--dark) !important;
}

.react-datepicker-wrapper{
    display: none !important;
}

.react-datepicker-popper{
    position: relative !important;
    transform: none !important;
    padding-top: 0 !important;
}


.meetingPopup .popupContainer{
    width: 600px;
}

.meetingPopup .selectInterviewContainer{
    max-width: 100%;
    max-height: 460px;
    overflow: auto;
}

.meetingPopup .slotWrapper{
    width: calc(50% - 8px);
}

.react-datepicker__current-month{
    margin-bottom: 10px !important;
}

.meetingPopup .slotWrapper label{
    padding: 8px 10px;
    padding-left: 10px !important;
}